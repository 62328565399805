@import "../../../styles/variables.scss";

.theecosystem {
  background-color: hsla(7, 26%, 10%, 1);
  background-image: radial-gradient(
      at 29% 41%,
      hsla(0, 14%, 5%, 0.5) 0px,
      transparent 50%
    ),
    radial-gradient(at 43% 57%, hsla(0, 17%, 3%, 0.74) 0px, transparent 50%);
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  position: relative;
  @include media("<=889px") {
    display: none;
  }
  .ecosystem-title-wrapper {
    background-color: hsla(7, 26%, 10%, 1);
    background-image: radial-gradient(
        at 35% 55%,
        hsla(0, 14%, 5%, 0.5) 0px,
        transparent 50%
      ),
      radial-gradient(at 82% 45%, hsla(0, 17%, 3%, 0.74) 0px, transparent 50%);
    text-align: center;
    font-family: $font-superStar;
    color: white;
    font-size: 124px;
    text-shadow: 0px 15.2108px 0px #000000;
    @include media("<=889px") {
      display: none;
    }
  }
  .ecosystem-cards-wrapper {
    position: absolute;
    top: 5rem;
    display: flex;
    gap: 3.5rem;
    width: 100vw;
    .panel {
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      margin-left: 2rem;
      border-radius: 32px;
      border: 1.75px solid #4e4e4e;
      width: 95%;
      background-color: #1f1b1f;
      height: 75vh;
      box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
      @include media("<=1550px") {
        height: 67.5vh;
      }
      .top-side {
        width: 100%;
        height: 90%;
        display: flex;
        .left-side {
          width: 50vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .information-box {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 60%;
            @include media("<=1550px") {
              gap: 2rem;
            }
            .title-wrapper {
              font-family: $font-superStar;
              font-size: 56px;
              color: #ffa556;
            }
            .ecosystemsubtitle-wrapper {
              color: white;
              font-family: $font-Pixellari;
              font-size: 22px;
              line-height: 120%;

              font-weight: 100;
              text-indent: 50px;
              max-width: 30rem;
              text-align: justify;
            }
            .goto-wrapper {
              display: flex;
              align-items: center;
              .goto-button {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-family: $font-superStar;
                padding: 0.4rem 1.25rem;
                background-color: #202020;
                color: #f95200;
                position: relative;
                cursor: $cursor-Pointer;
                border: 2px solid #3e3e3e;
                border-radius: 7.5px;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                &:hover {
                  background-color: rgba(255, 255, 255, 0.1);
                }
                &.disabled {
                  background-color: #111111;
                  color: rgb(254, 237, 237);
                  cursor: $cursor-Unavailable;
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .right-side {
          width: 50vw;
          display: flex;
          align-items: center;
          justify-content: center;
          .right-side-wrapper {
            position: relative;
            width: 40rem;
            height: 35rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media("<=1550px") {
              video {
                width: 30rem;
                height: 25rem;
              }
            }
            .plus-1,
            .plus-2,
            .plus-3,
            .plus-4 {
              position: absolute;
            }
            .plus-1 {
              top: 0;
              left: 0;
              @include media("<=1550px") {
                top: 5rem;
              }
            }
            .plus-2 {
              bottom: 0;
              left: 0;
              @include media("<=1550px") {
                bottom: 5rem;
              }
            }
            .plus-3 {
              bottom: 0;
              right: 0;
              @include media("<=1550px") {
                bottom: 5rem;
              }
            }
            .plus-4 {
              top: 0;
              right: 0;
              @include media("<=1550px") {
                top: 5rem;
              }
            }
          }
        }
      }
      .bottom-side {
        width: 97.5%;
        display: flex;
        margin-left: 1rem;
        position: relative;
        bottom: -1.5rem;
        .bottom-marquee {
          font-family: $font-superStar;
          color: #fff;
          overflow: hidden;
          span {
            margin: 0 1rem;
            font-size: 64px;
            word-spacing: -7.5px;
          }
        }
      }
    }
  }
}
