@import "../../styles/variables.scss";

.howitworks-popup-back {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
  opacity: 0;
}

.howitworks-popup {
  opacity: 0;
  position: fixed;
  width: 35rem;
  height: 35rem;
  background-color: #1c1e24;
  z-index: 201;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  padding: 0.75rem 2rem;
  box-shadow: rgba(35, 35, 34, 0.25) 0px 14px 28px,
    rgba(57, 57, 57, 0.346) 0px 10px 10px;
  .howitworks-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #f27921;
    font-family: $font-superStar;
    font-size: 32px;
    position: relative;
    .marketplace-esc-text {
      font-family: $font-Pixellari;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.15);
      position: absolute;
      right: -0.65rem;
      top: -0.5rem;
    }
    .close-marketplace-popup-btn {
      position: relative;
      right: -1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      cursor: $cursor-Pointer;
      border-radius: 5px;
      &:hover {
        background-color: #9f9f9f84;
      }
    }
  }
  .marketplace-questions-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 90%;
    .marketplace-question-wrapper {
      display: flex;
      flex-direction: column;
      .marketplace-question {
        font-family: $font-superStar;
        font-size: 24px;
        color: #ddd;
        margin-bottom: 0.5rem;
      }
      .marketplace-answer {
        font-family: $font-Pixellari;
        font-size: 18px;
        color: white;
        margin-bottom: 1rem;
        text-indent: 25px;
        line-height: 18px;
      }
    }
  }
}
