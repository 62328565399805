.access-pass {
  background-color: hsla(7, 26%, 10%, 1);
  width: 100%;
  position: relative;
  padding-top: 3em;
  .access-pass-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15vh;
    position: relative;
    background-image: url("../../assets/svg/access-page-nav-bg.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    @include media("<=950px") {
      background-image: none;
      background-color: #2f2f2f;
      height: auto;
      padding: 2rem 1rem;
    }

    .access-pass-links {
      display: flex;
      gap: 1rem;
      color: white;
      font-family: $font-superStar;
      padding: 0;
      font-size: 18px;
      position: relative;
      top: -1.5rem;
      @include media("<=950px") {
        gap: 1rem;
        font-size: 16px;
        top: 0;
      }
      @include media("<=700px") {
        gap: 0.5rem;
        flex-direction: column;
      }
      a {
        opacity: 60%;
        cursor: $cursor-Pointer;
        transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          opacity: 100%;
        }
      }
    }

    .access-page-center-logo {
      width: 100px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @include media("<=950px") {
        width: 80px;
      }
    }

    .access-page-buttons {
      display: flex;
      gap: 1rem;
      @include media("<=700px") {
        flex-direction: column;
      }
      button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 16px;
        cursor: $cursor-Pointer;
        user-select: none;
        border: none;
        font-family: $font-superStar;
        font-size: 24px;
        &:nth-child(1) {
          background-color: #81695b;
          box-shadow: 0px 6.545454502105713px 0px 0px #00000026;
          box-shadow: 0px 3.4909090995788574px 0px 0px #665348;
          color: #2c2625;
          transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
            box-shadow 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover,
          &:focus {
            transform: translateY(3.5px);
            box-shadow: 0 0 0 0 #00000026;
          }

          &:active {
            transform: translateY(6.5px);
            box-shadow: 0 0 0 0 #00000026;
          }
        }
        &:nth-child(2) {
          background: linear-gradient(180deg, #ffa556 0%, #ff944e 100%),
            linear-gradient(0deg, #ffa556, #ffa556);
          box-shadow: 0px 6.545454502105713px 0px 0px #00000026;
          box-shadow: 0px 3.4909090995788574px 0px 0px #ff7645;
          color: #e1220c;
          transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
            box-shadow 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

          &:hover,
          &:focus {
            transform: translateY(3.5px);
            box-shadow: 0 0 0 0 #00000026;
          }

          &:active {
            transform: translateY(6.5px);
            box-shadow: 0 0 0 0 #00000026;
          }
        }
        @include media("<=950px") {
          font-size: 18px;
          padding: 0.5rem 0.75rem;
        }
        @include media("<=700px") {
          font-size: 16px;
          padding: 0.5rem 0.5rem;
        }

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .access-pass-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 15rem;

    @include media("<=1920px") {
      padding: 0 10rem;
    }
    @include media("<=950px") {
      flex-direction: column;
      padding: 0;
      padding-top: 10rem;
      gap: 2.5rem;
    }

    @include media("<=380px") {
      padding-top: 15rem;
      gap: 0.5rem;
    }
    .access-pass-content-left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media("<=950px") {
        width: 400px;
      }
      img {
        width: 50%;
      }
    }

    .access-pass-content-right {
      display: flex;
      flex-direction: column;
      gap: 5rem;
      width: 50%;
      @include media("<=950px") {
        width: 100%;
        padding: 1rem 5rem;
        justify-content: center;
        align-items: center;
      }
      @include media("<=380px") {
        gap: 1rem;
      }
      .accessing-loader {
        font-size: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff944e;
    
        span {
            display: inline-block;
        }
    
        // Define keyframes for dot animation
        @keyframes dotAnimation {
            0%, 80%, 100% {
                opacity: 0;
            }
            40% {
                opacity: 1;
            }
        }
    
        // Apply animation to each dot
        span:nth-child(2) {
            animation: dotAnimation 1.2s infinite;
            animation-delay: 0s;
        }
        span:nth-child(3) {
            animation: dotAnimation 1.2s infinite;
            animation-delay: 0.2s;
        }
        span:nth-child(4) {
            animation: dotAnimation 1.2s infinite;
            animation-delay: 0.4s;
        }
    }
    
      .sale-access-title-wrapper {
        @include media("<=950px") {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          text-align: center;
        }
        .access-page-mint-title {
          color: #ffa556;
          font-size: 64px;
          font-family: $font-superStar;
          @include media("<=1450px") {
            font-size: 48px;
          }
          @include media("<=480px") {
            font-size: 36px;
            line-height: 1.2;
            width: max-content;
          }
        }
        .access-page-mint-subtitle {
          color: white;
          font-size: 24px;
          max-width: 60%;
          @include media("<=1920px") {
            max-width: 100%;
          }
          @include media("<=480px") {
            font-size: 18px;
            line-height: 1.2;
          }
        }
      }

      .salenotstarted {
        color: #ffa556;
        font-size: 48px;
        max-width: 60%;
        font-family: $font-superStar;
        @include media("<=1920px") {
          max-width: 100%;
        }
        @include media("<=1450px") {
          font-size: 36px;
        }
      }

      .salenotstarted-progress {
        clip-path: $pixelBorderRadius;
        width: 50rem;
        height: 15px;
        background: linear-gradient(-45deg, #8c8c8c, #dddddd, #2f2f2f, #a4a4a4);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        @include media("<=480px") {
          width: 100%;
        }
        &.sold-out-progress {
          background: linear-gradient(
            -45deg,
            #ff7645,
            #ffa556,
            #e1220c,
            #a4a4a4
          );
          background-size: 400% 400%;
          animation: gradient 5s ease infinite;
          @include media("<=480px") {
            width: 100%;
          }
        }

        @include media("<=1650px") {
          width: 40vw;
        }

        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      }

      // Mint.jsx
      .access-pass-info-container {
        display: flex;
        flex-direction: column;

        .salestarted-progress {
          clip-path: $pixelBorderRadius;
          width: 50rem;
          height: 15px;
          background-color: #2f2f2f;
          @include media("<=1650px") {
            width: 40vw;
          }
          .salestarted-inner-progress {
            background-color: #ffa556;
            width: 0%;
            height: 100%;
            transition: width 0.3s ease-in-out;
          }
        }

        .access-pass-info-top {
          display: flex;
          justify-content: space-between;
          max-width: 20rem;
          color: #fff;
          font-family: $font-superStar;
          gap: 2rem;
          .separator-line-element {
            width: 10px;
            height: 100%;
            background-color: #fff;
          }

          .access-pass-price-info-box,
          .supply-remaining {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .price-info,
            .supply-info {
              font-size: 36px;
              opacity: 60%;
              line-height: 1;
              width: max-content;
            }
            .supply-remaining-count,
            .price-eth-count {
              font-size: 48px;
              font-family: $font-superStar;
              color: #fff;
              width: max-content;
            }
          }
        }
      }

      .access-pass-btn {
        font-family: $font-superStar;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        padding: 0.1rem 1rem;
        border-radius: 16px;
        border: none;
        cursor: $cursor-Pointer;
        color: #fff;
        gap: 0.5rem;
        background: linear-gradient(180deg, #ffa556 0%, #ff944e 100%),
          linear-gradient(0deg, #ffa556, #ffa556);
        box-shadow: 0px 6.545454502105713px 0px 0px #00000026;
        box-shadow: 0px 3.4909090995788574px 0px 0px #ff7645;
        transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
          box-shadow 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover,
        &:focus {
          transform: translateY(3.5px);
          box-shadow: 0 0 0 0 #00000026;
        }

        &:active {
          transform: translateY(6.5px);
          box-shadow: 0 0 0 0 #00000026;
        }
        img {
          width: 35px;
        }
      }
    }
  }
  .already-minted-text{
    color: #ff944e;
    display: grid;
    justify-content: center;
    font-size: 1.7em;
  }
}
