@import "../../styles/variables.scss";

.metadata-info {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  .metadata-info--box {
    background-color: #151515;
    font-family: $font-superStar;
    font-weight: 100;
    position: relative;
    border-radius: 12.5px;
    width: 25rem;
    .metadata-close {
      position: absolute;
      right: 3px;
      top: 3px;
      border-radius: 5px;
      cursor: $cursor-Pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      svg {
        path {
          fill: black;
        }
      }
      &:hover {
        background-color: rgba(67, 67, 67, 0.347);
      }
    }

    .metadata-info--box__content {
      .metadata-info-image {
        width: 100%;
        height: 100%;
        .metadata-info-image-element {
          width: 100%;
          border-radius: 9px 9px 0 0;
          object-fit: cover;
        }
      }

      .metadata-info--box__header {
        font-size: 24px;
        padding: 1rem 2rem 1rem 1.5rem;
        color: #ff9750;
        line-height: normal;
      }

      .metadata-info-texts {
        padding: 1rem 1.5rem;
        padding-top: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 17.5rem;
        .metadata-info-texts--item__row {
          .metadata-info-texts--item__row--title {
            color: #dddddd84;
            font-size: 20px;
          }
          .metadata-info-texts--item__row--value {
            color: #ffffff;
            font-size: 20px;
          }
        }
      }
    }
  }
}
