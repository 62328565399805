@import "../../styles/variables.scss";

.cart-component {
  font-family: $font-superStar;
  z-index: 11;
  border-left: 2px solid #3e3e3e;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 30rem;
  background-color: #151515;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 7rem 1rem 1rem 1rem;

  @include media("<=1550px") {
    width: 25rem;
  }
  @include media("<=850px") {
    width: 100%;
  }
  &.active {
    transform: translateX(0);
  }
  .cart-title {
    font-size: 48px;
    color: #ddd;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media("<=1550px") {
      font-size: 32px;
    }
    .cart-close-btn {
      font-size: 24px;
      cursor: $cursor-Pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .clear-cart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: $cursor-Pointer;
    color: #7f1d1d;
    font-size: 24px;
    width: max-content;
    line-height: normal;
    position: absolute;
    left: 1rem;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include media("<=1550px") {
      font-size: 24px;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .carts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    max-height: 70%;
    overflow-y: auto;
    position: relative;

    .cart-empty {
      text-align: left;
      font-size: 32px;
      color: white;
    }
    .cart-item {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.75rem;
      border: 2px solid #3e3e3e;
      background-color: #3e3e3e;
      height: 7.5rem;
      border-radius: 12.5px;
      .cart-item-image {
        width: 100px;
        height: 100px;
        clip-path: $pixelBorderRadius;
      }
      .cart-item-details {
        font-size: 25px;
        color: #ddd;
      }
      .cart-remove-btn {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: $cursor-Pointer;
        border-radius: 5px;
        &:hover {
          background-color: #6f6f6f;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .buynow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .cart-gold {
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 2px solid #3e3e3e;
      padding: 0.6rem 0.4rem;
      gap: 0.25rem;
      margin-top: 1rem;
      font-size: 28px;
      font-family: $font-superStar;
      @include media("<=1550px") {
        font-size: 24px;
      }
      .daily-rewards-right {
        display: flex;
        flex-direction: row-reverse;
        gap: 0.5rem;
        position: relative;
        // top: 0.1rem;
        .daily-rewards-title {
          color: #ddd;
        }
      }
    }

    .cart-buy-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: $cursor-Pointer;
      margin-top: 1rem;
      background-color: #242424;
      color: #f27921;
      font-size: 32px;
      border: 2px solid #3e3e3e;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=1550px") {
        font-size: 24px;
      }
      &.loading {
        cursor: $cursor-Default;
        &:hover {
          background-color: #242424;
        }
      }
      &:hover {
        background-color: #3e3e3e;
      }
      span {
        position: relative;
        top: 0.1rem;
      }
    }
  }
}

.dutch-auction-container {
  color: white;
  .qty-wrapper {
    display: flex;
    align-items: center;
  }
  .bid-eth-wrapper {
    display: flex;
    align-items: center;
  }
}

.loader-cart-btn {
  width: 40px;
  height: 40px;
  border: 5px solid #f27921;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
