// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.ongoing-mission {
  font-family: $font-superStar;
  padding-top: 6rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ongoingmission-nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #ddd;
    padding: 5rem 2.5rem 0 2.5rem;
    width: 100%;
    max-width: 115rem;
    position: relative;
    @include media("<=950px") {
      margin-bottom: 1rem;
    }
    @include media("<=480px") {
      padding: 2rem 1.5rem 2rem 1.5rem;
      margin-bottom: 0;
    }
    .back-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 24px;

      @include media("<=480px") {
        font-size: 20px;
      }
      img {
        width: 25px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &:hover {
        cursor: $cursor-Pointer;
        text-decoration: underline;
        img {
          transform: translateX(-6px);
        }
      }
    }
    .mission-type {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 46px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @include media("<=480px") {
        font-size: 32px;
        transform: translate(-50%, -25px);
      }
    }
    .info-popup-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #ffa556;
      font-size: 24px;

      @include media("<=480px") {
        font-size: 20px;
      }
      p {
        position: relative;
        top: 0.035rem;
      }
      &:hover {
        cursor: $cursor-Pointer;
        text-decoration: underline;
      }
      svg {
        path {
          fill: #ffa556;
        }
      }
    }
  }

  .ongoingmission-wrapper {
    width: 100%;
    max-width: 115rem;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    @include media("<=950px") {
      padding: 0;
    }
    .battlemission-warning {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(255, 213, 65, 0.15);
      padding: 2rem;
      margin-bottom: 1.5rem;
      clip-path: $pixelBorderRadius;
      @include media("<=950px") {
        margin-top: 1rem;
        clip-path: none;
      }
      @include media("<=480px") {
        flex-direction: column;
        gap: 1rem;

        margin-bottom: 0.5rem;
        margin-top: 0;
      }
      .warning-left-wrapper {
        display: flex;
        align-items: center;
        gap: 2rem;
        .caution-icon {
          @include media("<=480px") {
            display: none;
          }
        }
        .warning-text {
          display: flex;
          flex-direction: column;
          @include media("<=950px") {
            gap: 0.5rem;
          }
          .warning-text-top {
            color: #f9a31b;
            font-size: 28px;
            @include media("<=950px") {
              font-size: 24px;
              line-height: 110%;
            }
          }
          .warning-text-bottom {
            color: #f9a31b;
            font-size: 20px;
            font-family: $font-Pixellari;
            @include media("<=950px") {
              font-size: 18px;
              max-width: 25rem;
              line-height: 110%;
            }
          }
        }
      }
      .warning-right-wrapper {
        display: flex;
        justify-content: center;
        .unstake-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2rem;
          font-size: 28px;
          background-color: #222;
          color: #f27921;
          cursor: $cursor-Pointer;
          border: 2px solid #3e3e3e;
          border-radius: 7.5px;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover {
            background-color: #333;
          }
          &.disabled {
            background-color: #222;
            color: #3e3e3e;
            border: 2px solid #3e3e3e;
            cursor: url(../../../assets/media/Cursors/cursorDefault.png), auto;
            &:hover {
              background-color: #222;
              color: #3e3e3e;
              border: 2px solid #3e3e3e;
            }
          }
        }
      }
    }

    .ongoingmission-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 32.5rem;
      background-color: #222;
      border: 2px dashed #b2b6c4;
      border-radius: 12px;
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px;
      @include media("<=950px") {
        height: auto;
        flex-direction: column;
        border-radius: 0;
        border-top: 2px dashed #b2b6c4;
        border-right: none;
        border-left: none;
        border-bottom: 2px dashed #b2b6c4;
      }
      @include media("<=480px") {
        border: none;
        box-shadow: none;
        background-color: transparent;
      }

      &.battle-mission {
        height: 37.5rem;
        padding: 0rem 1.5rem;
        @include media("<=950px") {
          flex-direction: row-reverse;
          position: relative;
          height: auto;
          border-radius: 0;
          padding: 2.5rem;
          border-radius: 0;
          border-top: 2px dashed #b2b6c4;
          border-right: none;
          border-left: none;
          border-bottom: 2px dashed #b2b6c4;
        }
        @include media("<=480px") {
          height: auto;
          padding: 2rem 0;
          border: none;
        }
      }
      .ongoingmission-container-left {
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 12px 0 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 0;
        position: relative;
        @include media("<=480px") {
          display: none;
        }
        &.not-battle {
          @include media("<=480px") {
            display: flex;
          }
        }
        .golds-info-wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          gap: 2.5rem;

          padding-left: 1.5rem;
          @include media("<=950px") {
            justify-content: center;
            padding: 0;
            margin-bottom: 1rem;
          }
          @include media("<=480px") {
            gap: 1.5rem;
          }
          .goldday-wrapper,
          .goldcount-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            font-size: 32px;
            @include media("<=480px") {
              font-size: 20px;
            }
            img {
              width: 35px;
              @include media("<=480px") {
                width: 25px;
              }
            }
          }
        }

        .image-wrapper {
          width: 100%;
          height: 20rem;
          top: 5rem;
          position: absolute;
          overflow: hidden;
          @include media("<=950px") {
            display: none;
          }
          .back-progress-image {
            filter: brightness(0.3);
            z-index: 3;
            mask-image: linear-gradient(to left, transparent, #000);
            -webkit-mask-image: linear-gradient(to left, transparent, #000);
          }
          .front-progress-image {
            z-index: 4;
            transition: 4s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
        .golds-info-wrapper-battle {
          width: 100%;
          display: flex;
          flex-direction: column;
          @include media("<=950px") {
            flex-direction: row;
            position: absolute;
            left: 2rem;
            top: -1rem;
            gap: 1rem;
          }
          @include media("<=480px") {
            display: none;
          }
          .price-stats-wrapper {
            position: relative;
            top: -1.5rem;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            font-size: 32px;
            @include media("<=950px") {
              font-size: 24px;
              justify-content: flex-start;
              align-items: flex-start;
            }
            .ethereumprice-wrapper {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 32px;
              @include media("<=950px") {
                font-size: 24px;
              }
              &.green {
                color: #22c55e;
              }
              &.red {
                color: #b91c1c;
              }
              svg {
                width: 35px;
                height: 35px;
                @include media("<=950px") {
                  width: 25px;
                  height: 25px;
                }
              }
            }
            .goldday-wrapper,
            .goldcount-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.25rem;
              font-size: 32px;
              @include media("<=950px") {
                font-size: 24px;
              }
              img {
                width: 35px;
                @include media("<=950px") {
                  width: 25px;
                }
              }
            }
          }
          .volume-info-wrapper {
            display: flex;
            gap: 0.5rem;
            font-size: 20px;
            padding-left: 0.4rem;
            position: relative;
            top: -1.75rem;
            @include media("<=950px") {
              align-items: center;
              font-size: 16px;
              justify-content: flex-start;
              align-items: flex-start;
              top: -1.1rem;
            }
            .separate {
              color: #f27921;
              @include media("<=950px") {
                display: none;
              }
            }
            .volume-day,
            .highest-volume,
            .lowest-volume {
              p {
                span {
                  color: #b2b6c5;
                }
              }
            }
          }
        }

        .exchange-wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          top: -1.6rem;
          display: flex;
          @include media("<=1500px") {
            width: calc(90% - 2.5rem);
          }
          @include media("<=1050px") {
            width: calc(90% - 5rem);
          }
          @include media("<=950px") {
            width: 100%;
            justify-content: flex-end;
            padding-right: 10rem;
          }
          @include media("<=480px") {
            display: none;
          }
          .exchange-wrapper-left {
            border-right: solid 1px #3a3e4f;
            border-bottom: solid 1px #3a3e4f;
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            @include media("<=950px") {
              display: none;
            }
            .demon-bear-wrapper {
              position: absolute;
              width: 100%;
              height: 100%;
              justify-content: center;
              display: flex;
              align-items: center;
              flex-direction: column;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

              .bear-code {
                z-index: 1;
                position: absolute;
                color: #151515;
                top: 43.35%;
                left: 50.5%;
                transform: translate(-50%, -50%);
                font-size: 20px;
              }
            
              .demon-bear {
                width: 175px;
                transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
                &.rotate-red {
                  rotate: 10deg;
                }
                &.rotate-green {
                  rotate: -10deg;
                }
              }
              .frame-bear {
                position: relative;
                width: 125px;
                left: 0.25rem;
              }
            }
            .green-area-wrapper {
              width: 100%;
              height: 45%;
              border-top: dashed 2px #94ef5a;
              display: flex;
              align-items: flex-end;
              overflow: hidden;
              .green-area {
                background: linear-gradient(180deg, #94ef5a 0%, #253c17 100%);
                width: 100%;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }

            .red-area-wrapper {
              width: 100%;
              height: 45%;
              border-bottom: dashed 2px #e1220c;
              overflow: hidden;
              .red-area {
                width: 100%;
                height: 0%;
                border-top: dashed 2px white;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                background: linear-gradient(
                  0deg,
                  #e1220c 0%,
                  rgba(224, 35, 13, 0.62) 100%
                );
              }
            }
          }
          .exchange-wrapper-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0.2rem 0;
            width: 10%;
            position: relative;
            @include media("<=950px") {
              padding: 0;
            }
            .ethvalue-wrapper {
              width: 100%;
              height: 100%;
              background-color: pink;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              height: 1.5rem;
              padding: 0 1rem;
              border-radius: 5px;
              z-index: 1;
              background-color: white;
              color: #151515;
              background: rgba(255, 255, 255, 0.6);
              box-shadow: 0 0 32px 0 #f2782137;
              backdrop-filter: blur(2px);
              -webkit-backdrop-filter: blur(2px);
              border: 1px solid rgba(255, 255, 255, 0.18);
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              top: 0.25rem;
              @include media("<=950px") {
                width: 5rem;
              }
            }
            .value-wrapper {
              font-size: 28px;
              position: relative;
              left: 1.5rem;
              display: flex;
              align-items: center;
              .line {
                rotate: -90deg;
                position: absolute;
                left: -1.5rem;
                width: 22.5px;
                height: 22.5px;
                rect {
                  fill: #3a3e4f;
                }
                &.green {
                  rect {
                    fill: #94ef5a;
                  }
                }
                &.red {
                  rect {
                    fill: #e1220c;
                  }
                }
              }
              &.green {
                color: #94ef5a;
              }
              &.red {
                color: #e1220c;
              }
            }
          }
        }

        .progress-wrapper {
          .progress-bar-gradient {
            width: 100%;
            background-color: red;
            height: 0.3rem;
            position: absolute;
            background: rgb(58, 62, 79);
            background: linear-gradient(
              90deg,
              rgba(58, 62, 79, 1) 59%,
              rgba(129, 92, 62, 1) 81%,
              rgba(242, 121, 33, 1) 100%
            );
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
            @keyframes gradient {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 150%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          }
          &.training {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
          .progress-labels-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .labels-rec-rescue{
              margin-left: 9rem;
            }
            .labels-inf-rescue{
              margin-left: 6rem;
            }
            .progress-label {
              width: 0.2%;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 20px;
              color: #6e6e6e;
              @include media("<=950px") {
                font-size: 18px;
              }
              @include media("<=480px") {
                font-size: 12px;
              }
              svg {
                rect {
                  fill: #6e6e6e;
                }
              }
              &.active {
                color: #ddd;
                svg {
                  rect {
                    fill: #ddd;
                  }
                }
              }
            }
            .progress-planning {
              width: 0.2%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              font-size: 24px;
              color: #6e6e6e;
              p{
                padding-left: 5px ;
              }
              @include media("<=950px") {
                font-size: 18px;
              }
              @include media("<=480px") {
                font-size: 12px;
              }
              svg {
                rect {
                  fill: #6e6e6e;
                }
              }
              &.active {
                color: #ddd;
                svg {
                  rect {
                    fill: #ddd;
                  }
                }
              }
            }
          }
        }
      }
      .ongoingmission-container-right {
        width: 30%;
        height: 100%;
        border-radius: 0 12px 12px 0;
        padding: 3.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media("<=1500px") {
          width: 40%;
        }
        @include media("<=1200px") {
          width: 50%;
        }
        @include media("<=950px") {
          width: 100%;
        }
        @include media("<=480px") {
          padding: 0;
        }
        .stats-container {
          width: 25rem;
          color: #ddd;
          background: linear-gradient(180deg, #090705 0%, #271412 100%), #191b21;
          border: 1px solid rgba(255, 165, 86, 0.4);
          box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.57),
            0px 41.7776px 33.4221px rgba(0, 0, 0, 0.409747),
            0px 22.3363px 17.869px rgba(0, 0, 0, 0.339781),
            0px 12.5216px 10.0172px rgba(0, 0, 0, 0.285),
            0px 6.6501px 5.32008px rgba(0, 0, 0, 0.230219),
            0px 2.76726px 2.21381px rgba(0, 0, 0, 0.160253);
          border-radius: 4px;
          @include media("<=950px") {
            height: 32.5rem;
          }
          @include media("<=480px") {
            width: 100%;
            height: auto;
            border-right: none;
            border-left: none;
            border-radius: 0;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          }
          .stats-title {
            color: #fff;
            font-size: 24px;
            padding: 1rem 1.5rem;
            @include media("<=950px") {
              font-size: 36px;
            }
            @include media("<=480px") {
              font-size: 24px;
            }
          }
          .bears-images {
            display: flex;
            gap: 1rem;
            padding: 1rem 1.5rem;
            .first-bearimg-wrapper,
            .second-bearimg-wrapper {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .bearcode-wrapper {
                position: absolute;
                background-color: #fff;
                color: black;
                border-radius: 32px;
                padding: 0 0.5rem;
                top: 5px;
                left: 5px;
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.57),
                  0px 12.5216px 10.0172px rgba(0, 0, 0, 0.285),
                  0px 6.6501px 5.32008px rgba(0, 0, 0, 0.230219),
                  0px 2.76726px 2.21381px rgba(0, 0, 0, 0.160253);
              }
              img {
                border-radius: 7.5px;
                width: 100px;
                height: 100px;
                object-fit: cover;
              }
            }
          }

          .stats-text {
            padding: 1rem 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .stats-text-top {
              display: flex;
              gap: 2rem;
              width: 20rem;
              justify-content: space-between;
              @include media("<=1500px") {
                gap: 0.5rem;
                width: 100%;
              }

              .gold-rate-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                .gold-rate-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .gold-rate-value {
                  font-size: 24px;
                  color: #fff;
                }
              }
              .total-golds-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                justify-content: flex-start;
                width: 7.5rem;
                text-align: end;
                .total-golds-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .total-golds-value {
                  font-size: 24px;
                  color: #fff;
                }
              }
            }
            .stats-text-bottom,
            .stats-text-middle {
              display: flex;
              gap: 2rem;
              width: 20rem;
              justify-content: space-between;
              @include media("<=1500px") {
                gap: 0.5rem;
                width: 100%;
              }
              @include media("<=480px") {
                width: 99%;
              }
              .missionstarted-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                .missionstarted-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .missionstarted-value {
                  position: relative;
                  font-size: 24px;
                  color: #fff;
                }
              }
              .time-remaining-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                text-align: end;
                .time-remaining-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .time-remaining-value {
                  font-size: 24px;
                  color: #fff;
                }
              }
            }
            .stats-text-bottom-battle {
              display: flex;
              gap: 2rem;
              width: 19.5rem;
              justify-content: space-between;
              @include media("<=1500px") {
                gap: 0.5rem;
                width: 100%;
              }
              @include media("<=480px") {
                width: 97%;
              }
              .currentpercent-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                .currentpercent-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .currentpercent-value {
                  font-size: 24px;
                  color: #fff;
                  @include media("<=480px") {
                    color: #f27921;
                  }
                }
              }

              .hostageprice-box {
                display: flex;
                flex-direction: column;
                line-height: normal;
                .hostageprice-title {
                  font-size: 18px;
                  color: #bfbfbf;
                }
                .hostageprice-value {
                  font-size: 24px;
                  color: #fff;
                  @include media("<=480px") {
                    color: #f27921;
                  }
                }
              }
            }
          }
          .stats-button-wrapper {
            width: 100%;
            padding: 1rem 1.5rem;
            display: flex;
            justify-content: center;

            .unstake-button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 2rem;
              font-size: 28px;
              background-color: #222;
              color: #f27921;
              cursor: $cursor-Pointer;
              border: 2px solid #3e3e3e;
              border-radius: 7.5px;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

              &:hover {
                background-color: #333;
              }
              &.disabled {
                background-color: #222;
                color: #3e3e3e;
                border: 2px solid #3e3e3e;
                cursor: url(../../../assets/media/Cursors/cursorDefault.png),
                  auto;
                &:hover {
                  background-color: #222;
                  color: #3e3e3e;
                  border: 2px solid #3e3e3e;
                }
              }
            }
            .proceed-metamask {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              color: #f27921;
              font-size: 18px;
              gap: 1rem;
              text-align: center;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 6px 6px 5px 5px;
  border: 5px solid #f27921;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f27921 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
