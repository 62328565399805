// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../../../assets/media/Cursors/cursorPointer.png"),
  pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.become-hostage-back {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000000b1;
}

.become-hostage-popup {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 101;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22.5rem;
  height: 32.5rem;
  background: linear-gradient(180deg, #090705 0%, #271412 100%), #d9d9d9;
  border: 1px solid rgba(255, 165, 86, 0.4);
  box-shadow: 0px 0px 250px rgba(255, 40, 40, 0.45);
  border-radius: 16px;
  font-family: $font-superStar;
  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: $cursor-Pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: $pixelBorderRadius;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      background-color: #888888;
    }
  }
  .becomehostage-top {
    display: flex;
    flex-direction: column;
    color: #ddd;
    justify-content: center;
    align-items: center;
    .ohno-text {
      font-size: 32px;
    }
    .become-hostage-text {
      font-size: 18px;
      font-family: $font-Pixellari;
      color: #b2b6c4;
    }
  }
  .becomehostage-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .hostage-image {
      width: 250px;
      height: auto;
      position: relative;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: fill;
        filter: saturate(0);
      }
      .hostage-band1 {
        position: absolute;
        bottom: 2rem;
        background: #e1720c;
        color: #fff;
        width: 98%;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 2.5px;
        font-size: 24px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      }
    }
    .rescue-button-wrapper {
      width: 100%;
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      .rescue-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        font-size: 28px;
        background-color: #222;
        color: #f27921;
        cursor: $cursor-Pointer;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          background-color: #333;
        }
        &.disabled {
          background-color: #222;
          color: #3e3e3e;
          border: 2px solid #3e3e3e;
          cursor: url(../../../../assets/media/Cursors/cursorDefault.png), auto;
          &:hover {
            background-color: #222;
            color: #3e3e3e;
            border: 2px solid #3e3e3e;
          }
        }
      }
      .days-left {
        color: #b2b6c4;
        font-size: 18px;
      }
    }
  }
}
