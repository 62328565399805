@import "../../styles/variables.scss";

.bg {
  display: flex;
  align-items: center;

  .centerer {
    display: flex;
    gap: 0.75rem;
    .discord-button {
      cursor: url("../../assets/media/Cursors/cursorPointer.png"), pointer !important;
      font-family: $font-superStar;
      width: 10rem !important;
      height: auto !important;
      padding: 0rem 0.25rem !important;
      clip-path: $pixelBorderRadius;
      display: flex !important;
      align-items: center !important;
      font-size: 14px !important;
      justify-content: center !important;
      div {
        display: flex;
        align-items: center !important;
      }
    }
    .wallet-logout {
      position: relative;
      cursor: $cursor-Pointer;
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      padding: 0.25rem 0.75rem 0.25rem 0.6rem;
      background-color: #0e0f12;
      width: 153px;
      height: 48px;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #010102;
      }

      span {
        color: #f27921;
        font-family: $font-superStar;
        font-size: 22px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &:hover span {
        opacity: 0;
      }

      .logout-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        opacity: 0;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &:hover .logout-text {
        opacity: 1;
      }
    }

    .connect-button {
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      background-color: #0e0f12;
      cursor: $cursor-Pointer;
      @include media("<=480px") {
        border: none;
      }
      &:hover {
        background-color: #010102;
      }
      .login-button {
        z-index: 1;
        font-family: $font-superStar;
        color: white;
        position: relative;
        border-radius: 7.5px;
        padding: 0.25rem 0.75rem 0.25rem 0.6rem;
        display: flex;
        align-items: center;
        font-size: 24px;
        gap: 0.5rem;
        @include media("<=480px") {
          border: 2px solid #3e3e3e;
          width: 40px;
          height: 40px;
          justify-content: center;
          padding: 0;
        }
        .connect-button-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.5rem 0 0;
          @include media("<=480px") {
            padding: 0.3rem 0;
          }
          img {
            width: 27.5px;
            @include media("<=480px") {
              width: 25px;
            }
          }
        }
        .line {
          position: absolute;
          left: 2.85rem;
          width: 2px;
          height: 100%;
          background-color: #3e3e3e;
          @include media("<=480px") {
            display: none;
          }
        }
        .connect-button-text {
          display: flex;
          align-items: center;
          padding-left: 0.1rem;
          @include media("<=480px") {
            display: none;
          }
        }
      }
    }
  }
}

.loading-connect-wallet {
  font-size: 24px;
  font-family: $font-superStar;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
