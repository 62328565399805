@import "../../styles/variables";

.loading-page {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-sentence {
    font-size: 28px;
    color: white;
  }
}

.loader-popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 28px;
  @include media("<=480px") {
    text-align: center;
    font-size: 24px;
  }
  .loader-popup-container {
    @include media("<=480px") {
      padding: 0 1rem;
    }
  }
}
