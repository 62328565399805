@import "../../styles/variables";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: hsla(7, 26%, 10%, 1);
  background-image: radial-gradient(
      at 21% 48%,
      hsla(0, 17%, 3%, 0.74) 0px,
      transparent 50%
    ),
    radial-gradient(at 89% 46%, hsla(0, 14%, 5%, 0.5) 0px, transparent 50%),
    radial-gradient(at 62% 55%, hsla(0, 17%, 3%, 0.74) 0px, transparent 50%);
  padding: 6rem 0 3rem 0;
  height: 40rem;
  .footer-top-wrapper {
    // background-color: rgba(255, 0, 0, 0.208);
    display: flex;
    justify-content: center;
    position: relative;

    svg {
      opacity: 0.7;
    }

    img {
      position: absolute;
      top: -3.5rem;
      z-index: 3;
    }

    .gold-effect {
      background-color: #ffd700;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: absolute;
      top: -1.75rem;
      z-index: 2;
      filter: blur(52px);
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=889px") {
        display: none;
      }
    }
    &:hover .gold-effect {
      width: 60px;
      height: 60px;
    }
  }
  .footer-marquee {
    z-index: 4;
    span {
      color: white;
      margin: 0 2.5rem;
      font-size: 124px;
      font-family: $font-superStar;
    }
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    width: 100%;
    .footer-bottom-left {
      display: flex;
      gap: 1rem;
      font-size: 24px;
      font-family: $font-superStar;
      @include media("<=480px") {
        font-size: 20px;
        flex-direction: column;
        gap: 0.25rem;
      }
      .footer-barberians {
        color: white;
        word-spacing: -4px;
      }
      .footer-terms {
        cursor: $cursor-Pointer;
        color: rgba(255, 255, 255, 0.6);
        transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          color: #ffffff;
        }
      }
      .footer-faq {
        cursor: $cursor-Pointer;
        color: rgba(255, 255, 255, 0.6);
        transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          color: #ffffff;
        }
      }
    }
    .footer-bottom-right {
      display: flex;
      align-items: center;
      gap: 2rem;
      .twitter-button {
        padding: 0.5rem 1rem;
        clip-path: $pixelBorderRadius;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          cursor: $cursor-Pointer;
          background-color: #ffffff78;
        }
      }
    }
  }
}

// Footer buttons
.footer-bottom-right-mobile {
  display: flex;
  align-items: center;
  gap: 2rem;
  @include media(">889px") {
    display: none;
  }
  @include media("<=480px") {
    gap: 0.5rem;
  }
}

$outline-width: 6px;
$shadow-width: 4px;

$button-background: #5865f2;
$sec-button-background: #6470ef;
$shadow-color: #2c3ce4;

$alt-button-background: #fa9b53;
$alt-sec-button-background: #e5813f;
$alt-shadow-color: #9e4a0b;

$reset-button-background: #1da1f2;
$reset-sec-button-background: #4cabe7;
$reset-shadow-color: #0a4f7a;

.footer-cta-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: $cursor-Pointer;
  background: $button-background;
  position: relative;
  text-align: center;
  font-size: 28px;
  padding: 10px 20px;
  font-family: $font-superStar;
  text-decoration: none;
  color: white;
  box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px $shadow-color;
  opacity: 1;
  @include media("<=889px") {
    font-size: 20px;
    padding: 5px 10px;
    display: none;
  }
  &:hover,
  &:focus {
    background: $sec-button-background;
    box-shadow: inset (-$shadow-width * 1.5) (-$shadow-width * 1.5) 0px 0px
      $shadow-color;
  }

  &:active {
    box-shadow: inset $shadow-width $shadow-width 0px 0px $shadow-color;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  &:before {
    top: -$outline-width;
    left: 0;
    border-top: $outline-width black solid;
    border-bottom: $outline-width black solid;
  }

  &:after {
    left: -$outline-width;
    top: 0;
    border-left: $outline-width black solid;
    border-right: $outline-width black solid;
  }

  &--reset {
    background: $reset-button-background;
    box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px
      $reset-shadow-color;

    &:hover,
    &:focus {
      background: $reset-sec-button-background;
      box-shadow: inset (-$shadow-width * 1.5) (-$shadow-width * 1.5) 0px 0px
        $reset-shadow-color;
    }

    &:active {
      box-shadow: inset $shadow-width $shadow-width 0px 0px $reset-shadow-color;
    }
  }

  &--proceed {
    background: $alt-button-background;
    box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px
      $alt-shadow-color;

    &:hover,
    &:focus {
      background: $alt-sec-button-background;
      box-shadow: inset (-$shadow-width * 1.5) (-$shadow-width * 1.5) 0px 0px
        $alt-shadow-color;
    }

    &:active {
      box-shadow: inset $shadow-width $shadow-width 0px 0px $alt-shadow-color;
    }
  }
}
