// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../../../assets/media/Cursors/cursorPointer.png"),
  pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.dashboard-info-popup-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  opacity: 0;
}

.dashboard-info-popup {
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  width: 35rem;
  height: 32.5rem;
  background-color: #202020;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  border: 12.5px solid transparent;
  transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  @include media("<=600px") {
    width: 100%;
    border-radius: 0;
  }
  .faqmodal-header-close {
    cursor: $cursor-Pointer;
    color: white;
    right: 5px;
    top: 0;
    width: 35px;
    height: 35px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    clip-path: $pixelBorderRadius;
    &:hover {
      background-color: #646464;
    }
    svg {
      path {
        fill: rgb(151, 151, 151);
      }
    }
  }
  .faqmodal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(200, 200, 200);
    font-family: $font-superStar;
    border-bottom: 1px solid #333;

    .modal-tabs {
      display: flex;
      gap: 1.5rem;
      font-size: 20px;
      .faqmodal-header-title {
        cursor: $cursor-Pointer;
        &:hover {
          color: white;
        }
        &.active {
          color: #ffa556;
          border-bottom: 2px solid #ffa556;
          pointer-events: none;
        }
      }
      .faq-button {
        cursor: $cursor-Pointer;
        &:hover {
          color: white;
        }
        &.active {
          color: #ffa556;
          border-bottom: 2px solid #ffa556;
        }
      }
    }
  }
  .faqmodal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: normal;
    font-size: 22px;
    height: 43rem;
    overflow-y: auto;
    padding-bottom: 2rem;
    .tutorial-text {
      font-size: 18px;
      text-indent: 20px;
      max-width: 30rem;
      text-align: left;
    }
    .question {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      ul {
        list-style-type: decimal;
      }
      .faqmodal-content-title {
        font-family: $font-superStar;
        color: rgb(151, 151, 151);
      }
      p {
        font-size: 18px;
        text-indent: 20px;
        max-width: 30rem;
        text-align: left;
      }
    }
    .faq-question {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .faqmodal-content-title {
        font-family: $font-superStar;
        color: rgb(151, 151, 151);
      }
      p {
        font-size: 18px;
        text-indent: 20px;
        max-width: 30rem;
        text-align: left;
        .note {
          margin-top: 0.25rem;

          display: flex;
          text-indent: 0;
        }
      }
    }
  }
}
